import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import Label from "../../components/atoms/Label";
import InputText from "../../components/atoms/InputText";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { createNach } from "../../services/sanctions";
import { useLocation ,useNavigate} from "react-router-dom";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { MandateType } from "../../utils/types";
import { ApplicantStage } from "../../utils/applicationStage";

// import { SubscriptionsCheckoutSdk } from "../../services/cashfreeEmandate";






function Mandate() {
  const [mandateStatus, setMandateStatus] = useState<MandateType>({});

  const { userId, applicationId } = useContext(DataContext) as DataContextType;

  const [radioSelectedAccount, setRadioSelectedAccount] = useState("SAVINGS");
  const [radioSelectedMandate, setRadioSelectedMandate] =
    useState("NET_BANKING");

  const [state, setState] = useState({
    accountNumber: "",
    ifscCode: "",
  });
  const navigate=useNavigate();
  const location = useLocation();

  const { accountNumber, ifscCode } = state;

  const handleChange = (key: any, val: any) => {
    setState({ ...state, [key]: val });
  };

  async function getRazorpayUrl() {
    const response = await createNach({
      accountNumber: accountNumber,
      accountType: radioSelectedAccount,
      ifscCode: ifscCode,
      mandateType: radioSelectedMandate,
      userId: location?.state?.data?.userId,
      applicationId: location?.state?.data?.applicationId,
      mobile: location?.state?.data?.mobile,
    });

    window.open(response?.data?.url, "_blank", "noopener,noreferrer");
  }


  //cashfree sdk

  // async function getCashfreepayUrl() {
    
  //   try {
  //     const response = await createNach({
  //       userId: location?.state?.data?.userId,
  //       applicationId: location?.state?.data?.applicationId,
  //     });
  //     const responseFromSdk = SubscriptionsCheckoutSdk(response?.data)
  //   } catch (error) {
  //     console.error("Error fetching Razorpay URL", error);
  //   } finally {
      
  //   }
  // }







   const [authToken, setAuthToken] = useState({
    mob: "",
    value: "",
  });
const authData = sessionStorage.getItem("data")
  useEffect(() => {
    // console.log(flag);
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/generate-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: location?.state?.data?.mobile,
        userId: location?.state?.data?.userId,
        applicationId: location?.state?.data?.applicationId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        fetch(`${process.env.REACT_APP_DASHBOARD_URL}/insurfin/info`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${data.data}`,
          },
        })
          .then((res) => res.json())
          .then((data) => {
            console.log(data);
            setState({
              accountNumber: data.data.accountNumber,
              ifscCode: data.data.ifsc,
            });
          });
      });
      fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/generate-token`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          mobile: location?.state?.data?.mobile,
          userId: location?.state?.data?.userId,
          applicationId: location?.state?.data?.applicationId,
        }),
      })
        .then((res) => res.json())
        .then((data) => {
          setAuthToken({
            mob: location?.state?.data?.mobile,
            value: data.data,
          });
          setInterval(() => {
            fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
                headers: {
                  Authorization: `Bearer ${data.data}`,
                  "Content-Type": "application/x-www-form-urlencoded",
                },
              })
                .then((res) => res.json())
                .then((data) => {
                  // console.log(data);
                  setMandateStatus(data.data);
                  console.log(data.data.enachStatus,"data.data.enachStatus")
                  if(data.data.enachStatus === 4 || data.data.enachStatus===6){
                    navigate(`/${authData}`)
                    window.location.reload()
                  }
                });
          }, 2000);
          // fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
          //   headers: {
          //     Authorization: `Bearer ${data.data}`,
          //     "Content-Type": "application/x-www-form-urlencoded",
          //   },
          // })
          //   .then((res) => res.json())
          //   .then((data) => {
          //     setMandateStatus(data.data);
          //     // console.log(data);
          //     if(data.data.enachStatus === 4 || data.data.enachStatus===6){
          //       navigate(`/${authData}`)
          //     }
              
          //   });
        });
  }, [location]);

  useEffect(() => {
    setInterval (()=>{
      fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${location?.state?.data?.applicationId}`
      )
        .then((res) => res.json())
        .then((res) => {
          if (res?.data?.stage) {
            switch (res?.data?.stage) {
             
              case ApplicantStage.PFCollections:
                navigate("/pf-collection", {
                  state: { data: location?.state?.data },
                });
                break;
                case ApplicantStage.Disbursed:
                  navigate("/disbursed-page", {
                    state: { data: location?.state?.data },
                  });
                  break;
            }
          }
        })
        .catch((err) => console.log(err));
    },2000);
    
  }, [location?.state?.data?.applicationId, location?.state?.data, navigate]);

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.childContainer}>
            <div className={styles.inputField}>
              <Label text="Account Number" />
              <InputText
                placeholder="Account Number"
                type="number"
                value={accountNumber}
                changeHandler={(e) =>
                  handleChange("accountNumber", e.target.value)
                } disabled
              />
            </div>

            <div className={styles.inputField}>
              <Label text="IFSC Code" />
              <InputText
                placeholder="IFSC Code"
                type="text"
                value={ifscCode}
                changeHandler={(e) => handleChange("ifscCode", e.target.value)} disabled
              />
            </div>
            <div className={styles.inputField}>
              <Label text="Account Type" />
              <div
                onChange={(event) =>
                  setRadioSelectedAccount(
                    (event.target as HTMLInputElement).value
                  )
                }
                defaultValue="SAVINGS"
              >
                <input
                  type="radio"
                  value="SAVINGS"
                  name="student"
                  checked={radioSelectedAccount === "SAVINGS"}
                />{" "}
                Savings <br />
                <input
                  type="radio"
                  value="CUURENT"
                  name="student"
                  checked={radioSelectedAccount === "CUURENT"}
                  disabled
                />{" "}
                Current
              </div>
            </div>
            <div className={styles.inputField}>
              <Label text="How do you want to authenticate your account?" />
              <div
                onChange={(event) =>
                  setRadioSelectedMandate(
                    (event.target as HTMLInputElement).value
                  )
                }
                defaultValue="NET_BANKING"
              >
                <input
                  type="radio"
                  value="NET_BANKING"
                  name="Net Banking"
                  checked={radioSelectedMandate === "NET_BANKING"}
                />{" "}
                Net Banking <br />
                <input
                  type="radio"
                  value="DEBIT_CARD"
                  name="Debit Card"
                  checked={radioSelectedMandate === "DEBIT_CARD"}
                />{" "}
                Debit Card
              </div>
            </div>
            <Button insureFin={location?.state?.data?.channelId === 5}
              onPress={() => {
                // initiateDigio();
                getRazorpayUrl();
                // getCashfreepayUrl();
              }}
              text="SUBMIT"
            />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Mandate;
