import React, { useState, useEffect } from "react";
import styles from "./index.module.css";
import ParentIcon from "../../images/icons/parent_icon.svg";
import CoAppIcon from "../../images/icons/co_app_icon.svg";
import StudentIcon from "../../images/icons/student_icon.svg";
import Button from "../../components/atoms/Button";
import CourseDetailsIcon from "../../images/static_assests/course_details.svg";
import LoanStepCard from "../../components/molecules/Card";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import InputText from "../../components/atoms/InputText";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import ArrowLeft from "../../images/icons/arrow_left.svg";
import Label from "../../components/atoms/Label";
import GreenTick from "../../images/icons/sanctions-icons/green-tick.svg";

function BankDetailsInfo() {
  const [newAuthToken, setNewAuthToken] = useState("");
  const [userData, setUserData] = useState<{
    applicationId: string;
    userId: string;
    email: string;
    panId: string;
    dateOfBirth: string;
    instituteId: string;
    firstName: string;
    lastName: string;
    mobile: string;
    course: string;
    fees: number;
    studentName: string;
    instituteName: string;
    redirectUrl: string;
    channelId: number;
  }>({
    applicationId: "",
    userId: "",
    instituteId: "",
    firstName: "",
    lastName: "",
    panId: "",
    dateOfBirth: "",
    email: "",
    mobile: "",
    course: "",
    fees: 0,
    studentName: "",
    instituteName: "",
    redirectUrl: "",
    channelId: 0,
  });

  const [fetchedAccountNumber, setFetchedAccountNumber] = useState("");
  const [fetchedIfscCode, setFetchedIfscCode] = useState("");

  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [beneficiaryName, setBeneficiaryName] = useState("");

  const [isBeneficiaryNameDisable, setIsBeneficiaryNameDisable] =
    useState(true);

  const [isAccountNumberDisable, setIsAccountNumberDisable] = useState(false);
  const [isIfscCodeDisable, setIsIfscCodeDisable] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const [fetchedBankDetails, setFetchedBankDetails] = useState("");

  const [verifiedStatus, setVerifiedStatus] = useState(false);

  console.log("this is location from bank info page", location);

  function handleEditAccountDetails() {
    setIsBeneficiaryNameDisable(false);
    setIsAccountNumberDisable(false);
    setIsIfscCodeDisable(false);
    setVerifiedStatus(false);
    setBeneficiaryName("");
  }

  async function BankAccountVerifiedStatusApi() {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", `Bearer ${newAuthToken}`);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_DASHBOARD_URL}/bank-account-verification/verified-status?userId=${userData.userId}&applicationId=${userData.applicationId}`,
        {
          method: "GET",
          headers: myHeaders,
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const res = await response.json();
      return res;
    } catch (error) {
      console.error("Error fetching user verified status:", error);
      return null;
    }
  }

  function loginAuthToken() {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/login/auth`, {
      method: "POST",
      body: JSON.stringify({
        memberId: "26ae9a50-b0cd-4e7a-abc4-705edd5ae399",
        //memberId: userData.userId,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((result1) => {
        const authToken = result1.data;

        setNewAuthToken(authToken);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  async function bankAccountVerificationAndBeneficiaryNameApi() {
    if (accountNumber && ifscCode) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${newAuthToken}`);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/bank-account-verification/verify`,
          {
            method: "POST",
            body: JSON.stringify({
              accountNumber: accountNumber,
              ifscCode: ifscCode,
              userId: userData.userId,
              applicationId: userData.applicationId,
            }),
            headers: myHeaders,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const res = await response.json();
        return res;
      } catch (error) {
        console.error("Error fetching user verified status:", error);
        return null;
      }
    } else {
      alert("Please, Enter reqiured Fields");
    }
  }

  useEffect(() => {
    setUserData(location?.state?.data);

    loginAuthToken();

    async function normalFunction() {
      const response = await BankAccountVerifiedStatusApi();
      console.log("this is response normal funtion", response);

      if (response?.status === 1) {
        navigate("/bankstatement-evaluation", {
          state: { data: location?.state?.data },
        });
      }

      if (response?.status === 0 && response?.accountNumber && response?.ifsc) {
        //setFetchedBankDetails()
        console.log("this is response normal funtion", response);

        setAccountNumber(response?.accountNumber);
        setIfscCode(response?.ifsc);
        // setIsAccountNumberDisable(true);
        // setIsIfscCodeDisable(true);

        setFetchedAccountNumber(response?.accountNumber);
        setFetchedIfscCode(response?.ifsc);

        // if (response?.accountNumber && response?.ifsc) {
        //   const beneficiaryResponse =
        //     await bankAccountVerificationAndBeneficiaryNameApi();
        //   if (beneficiaryResponse === null) {
        //     alert("unable to verify beneficiary and account details");
        //   }

        //   console.log("this is beneficiary name", beneficiaryResponse);
        // }
      } else if (
        response?.status === 0 &&
        !(response?.accountNumber && response?.ifsc)
      ) {
        alert("Unable to fetch bank details, Please Enter Details");
        setIsAccountNumberDisable(false);
        setIsIfscCodeDisable(false);
      }
    }

    normalFunction();
  }, [userData, newAuthToken]);

  async function addVerifiedBankDetailsApi() {
    if (verifiedStatus) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", `Bearer ${newAuthToken}`);

      try {
        const response = await fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/bank-account-verification/add`,
          {
            method: "POST",
            body: JSON.stringify({
              accountNumber: accountNumber,
              ifscCode: ifscCode,
              userId: userData.userId,
              applicationId: userData.applicationId,
              beneficiaryName: beneficiaryName,
            }),
            headers: myHeaders,
          }
        );

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const res = await response.json();
        return res;
      } catch (error) {
        console.error("Error fetching add verified details:", error);
        return null;
      }
    }
  }

  async function afterBankValidation() {
    const addVerifiedAccountResponse = await addVerifiedBankDetailsApi();
    console.log("this is verified response", addVerifiedAccountResponse);
    navigate("/bankstatement-evaluation", {
      state: { data: location?.state?.data },
    });
  }

  // async function verifyBeneficiaryDetails() {
  //   if (accountNumber && ifscCode) {
  //     const bankaccountValidation =
  //       fetchedAccountNumber.substring(fetchedAccountNumber.length - 4) ===
  //       accountNumber.substring(accountNumber.length - 4);

  //       {/* if bank fetched details not exists begin */}

  //       if (fetchedAccountNumber === "" && fetchedIfscCode === "") {
  //         console.log("this is fetched details null",fetchedAccountNumber,fetchedIfscCode)
  //         //navigate("/bankstatement-evaluation");
  //         const beneficiaryResponsed =
  //           await bankAccountVerificationAndBeneficiaryNameApi();

  //         if (beneficiaryResponsed?.message?.data !== null) {
  //           setBeneficiaryName(beneficiaryResponsed?.data?.beneficiaryName);

  //           if (beneficiaryResponsed?.data?.status === "VERIFIED") {
  //             setVerifiedStatus(true);
  //             setIsAccountNumberDisable(true);
  //             setIsIfscCodeDisable(true);
  //           }
  //           else {
  //             // alert(beneficiaryResponsed?.data?.remark)
  //             alert("Invalid details")
  //           }
  //           //else {
  //           //   alert("Invalid A/c numb or Ifsc");
  //           //   setVerifiedStatus(false);
  //           //   setIsAccountNumberDisable(false);
  //           //   setIsIfscCodeDisable(false);
  //           // }
  //         }

  //         if (beneficiaryResponsed === null) {
  //           setVerifiedStatus(false);
  //           setIsAccountNumberDisable(false);
  //           setIsIfscCodeDisable(false);

  //           alert("unable to verify beneficiary and account details");
  //         }
  //       }

  //       {/* end */}

  //     if (bankaccountValidation) {
  //       //navigate("/bankstatement-evaluation");
  //       const beneficiaryResponse =
  //         await bankAccountVerificationAndBeneficiaryNameApi();
  //       console.log("this is beneficiary res", beneficiaryResponse);

  //       if (beneficiaryResponse?.message?.data !== null) {
  //         setBeneficiaryName(beneficiaryResponse?.data?.beneficiaryName);

  //         if (beneficiaryResponse?.data?.status === "VERIFIED") {
  //           setVerifiedStatus(true);
  //           setIsAccountNumberDisable(true);
  //           setIsIfscCodeDisable(true);
  //         }
  //         else {
  //           // alert(beneficiaryResponse?.data?.remark)
  //           alert("Invalid details")
  //         }
  //         //else {
  //         //   alert("Invalid A/c numb or Ifsc");
  //         //   setVerifiedStatus(false);
  //         //   setIsAccountNumberDisable(false);
  //         //   setIsIfscCodeDisable(false);
  //         // }
  //       }

  //       if (beneficiaryResponse === null) {
  //         setVerifiedStatus(false);
  //         setIsAccountNumberDisable(false);
  //         setIsIfscCodeDisable(false);
  //         //alert(beneficiaryResponse?.message?.message)

  //         alert("unable to verify beneficiary and account details");
  //       }
  //     }
  //     else {
  //       alert("Re-check Account Number");
  //     }

  //   } else {
  //     alert("Please, fill all the fields");
  //   }
  // }

  {
    /* begin */
  }
  async function verifyBeneficiaryDetails() {
    if (!accountNumber || !ifscCode) {
      alert("Please, fill all the fields");
      return;
    }

    const bankAccountValidation =
      fetchedAccountNumber.substring(fetchedAccountNumber.length - 4) ===
      accountNumber.substring(accountNumber.length - 4);

    // If bank fetched details does not exist
    if (!fetchedAccountNumber && !fetchedIfscCode) {
      console.log(
        "Fetched details are null",
        fetchedAccountNumber,
        fetchedIfscCode
      );

      const beneficiaryResponse =
        await bankAccountVerificationAndBeneficiaryNameApi();

      handleBeneficiaryResponse(beneficiaryResponse);
      return;
    }

    // Proceed if the bank account validation passed
    if (bankAccountValidation) {
      const beneficiaryResponse =
        await bankAccountVerificationAndBeneficiaryNameApi();
      console.log("Beneficiary response:", beneficiaryResponse);

      handleBeneficiaryResponse(beneficiaryResponse);
    } else {
      alert("Entered account number is not matching with your account number");
    }
  }

  // helping function to handle api response logic
  function handleBeneficiaryResponse(beneficiaryResponse: any) {
    if (beneficiaryResponse?.message?.data !== null) {
      setBeneficiaryName(beneficiaryResponse?.data?.beneficiaryName);

      if (beneficiaryResponse?.data?.status === "VERIFIED") {
        setVerifiedStatus(true);
        setIsAccountNumberDisable(true);
        setIsIfscCodeDisable(true);
      } else {
        alert("Invalid details");
      }
    } else if (beneficiaryResponse === null) {
      setVerifiedStatus(false);
      setIsAccountNumberDisable(false);
      setIsIfscCodeDisable(false);
      alert("Unable to verify beneficiary and account details");
    }
  }

  {
    /*end  */
  }

  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div
          className={`${styles.container}  mx-sm-auto col-xs-10 col-sm-10 col-md-8 col-lg-6`}
        >
          <LoanStepCard
            // description="Permanent Address & Current Location"
            title="Bank Details Validation"
            image={CourseDetailsIcon}
          />

          <div className={styles.content}>
            <div>
              <div
                style={{
                  padding: "1rem",
                  background: "var(--box-background-dark)",
                  border: "1px solid var(--primary-border-dark)",
                  borderRadius: "12px 12px 0px 0px",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                }}
              >
                <p
                  style={{
                    fontSize: "1em",
                    fontWeight: "bold",
                    margin: "0.5em",
                  }}
                >
                  Bank Details
                </p>

                {/*
              <EditNoteSharpIcon sx={{ fontSize: 40 }} color="primary" onClick ={handleEditPermanentAddress}/>
              */}
                {/* <button
                  style={{
                    color: "#2c6ce3",
                    border: "1px solid #2c6ce3",
                    padding: "0.2rem 1rem",
                    borderRadius: "10px",
                  }}
                  onClick={handleEditAccountDetails}
                >
                  Edit
                </button> */}
                {verifiedStatus && (
                  <a href="#" onClick={handleEditAccountDetails}>
                    <p style={{ fontSize: "1rem", marginTop: "0.5rem" }}>
                      Edit
                    </p>
                  </a>
                )}
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  // justifyContent: "center",
                  // alignItems: "center",
                  gap: "1rem",
                  border: "1px solid var(--primary-border-dark)",
                  background: "var(--box-background)",
                  padding: "1rem",
                  boxShadow: "0px 3px 3px var(--primary-border-shadow)",
                  borderRadius: "0px 0px 12px 12px",
                }}
              >
                <div //style={{ display: "block"}}
                >
                  <div style={{ marginBottom: "20px" }}>
                    
                      

                      <Label  text="Account Number"/>
                      {/* <p style={{ fontSize: "0.7rem", marginLeft: "10px" }}>
                        Note: Click Edit incase of account Number is Masked
                      </p> */}
                  
                    <InputText
                      gridArea="Account Number"
                      square
                      placeholder="Account Number"
                      value={accountNumber}
                      changeHandler={(e) => setAccountNumber(e.target.value)}
                      disabled={isAccountNumberDisable}
                    />
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    <Label text="IFSC code" />
                    <InputText
                      gridArea="ifsccode"
                      square
                      placeholder="IFSC Code"
                      value={ifscCode}
                      changeHandler={(e) => setIfscCode(e.target.value)}
                      disabled={isIfscCodeDisable}
                    />
                  </div>

                  <div style={{ marginBottom: "20px" }}>
                    <div className={styles.bankdetailsContainer}>
                      <Label text="Beneficiary Name" />

                      {verifiedStatus ? (
                        <>
                          <img
                            src={GreenTick}
                            alt=""
                            //className={styles.sanctionsGreenTick}
                          />
                        </>
                      ) : (
                        <button
                          className={styles.verifyButton}
                          onClick={verifyBeneficiaryDetails}
                        >
                          Verify
                        </button>
                      )}
                    </div>
                    <InputText
                      gridArea="BeneficiaryName"
                      square
                      placeholder="Beneficiary Name"
                      value={beneficiaryName}
                      disabled={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Button
            onPress={() => {
              afterBankValidation();
            }}
            text={"Next"}
            imageRight={ArrowLeft}
            color={"customblue"}
            disabled={
              !(accountNumber && ifscCode && beneficiaryName && verifiedStatus)
            }
          />
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default BankDetailsInfo;
