import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import CheckCircleIcon from "../../images/icons/check-circle.svg";
import GreenTick from "../../images/icons/sanctions-icons/green-tick.svg";
import NextArrow from "../../images/icons/sanctions-icons/next-arrow.svg";
import RedCross from "../../images/icons/sanctions-icons/red-cross.svg";
import GreyDot from "../../images/icons/sanctions-icons/grey-dot.svg";
import Navbar from "../../components/molecules/Navbar";
import Footer from "../../components/molecules/Footer";
import Button from "../../components/atoms/Button";
import { useLocation, useNavigate } from "react-router-dom";
import { MandateType } from "../../utils/types";
import { createNach } from "../../services/sanctions";
import {
  AgreementStatus,
  DigilockerStatus,
  MandateStatus,
  SelfieStatus,
} from "../../utils/config";
// import { process.env.REACT_APP_DASHBOARD_URL } from "../../utils/keys";
import { DataContext } from "../../context/DataContext";
import { DataContextType } from "../../types/DataContextType";
import { ApplicantStage } from "../../utils/applicationStage";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

// import { SubscriptionsCheckoutSdk } from "../../services/cashfreeEmandate";






let Digio: any;

function Sanctions() {
  const [mandateStatus, setMandateStatus] = useState<MandateType>({});

  // const data = sessionStorage.getItem("fmk_data");

  const location = useLocation();

  const { userId, applicationId, mobileNumber, redirectUrl } = useContext(
    DataContext
  ) as DataContextType;

  const [flag, setFlag] = useState(false);

  const navigate = useNavigate();

  // const authToken = {
  //   mob: "1234567890",
  //   value: "1234567890",
  // };

  const [authToken, setAuthToken] = useState({
    mob: "",
    value: "",
  });

  // const [authToken] = useSessionStorage("feemonk_data", "");

  const [loading, setLoading] = useState(false);

  const [loaderAgreement, setLoaderAgreement] = useState(false);

  useEffect(() => {
    // console.log(flag);
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/generate-token`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        mobile: location?.state?.data?.mobile,
        userId: location?.state?.data?.userId,
        applicationId: location?.state?.data?.applicationId,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        setAuthToken({
          mob: location?.state?.data?.mobile,
          value: data.data,
        });
        setInterval(() => {
              fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
                  headers: {
                    Authorization: `Bearer ${data.data}`,
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                })
                  .then((res) => res.json())
                  .then((data) => {
                    // console.log(data);
                    setMandateStatus(data.data);
                  });
            }, 2000);
      });
      
  }, [flag, location]);

  // const callAuthApiDelayed = () => {
  //   setInterval(() => {
  //     fetch(`${process.env.REACT_APP_DASHBOARD_URL}/integrations-login/auth`, {
  //         headers: {
  //           Authorization: `Bearer ${authToken.value}`,
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //       })
  //         .then((res) => res.json())
  //         .then((data) => {
  //           // console.log(data);
  //           setMandateStatus(data.data);
  //         });
  //   }, 2000);
  // };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_DASHBOARD_URL}/admin/application/stage?applicationId=${location?.state?.data?.applicationId}`
    )
      .then((res) => res.json())
      .then((res) => {
        if (res?.data?.stage) {
          switch (res?.data?.stage) {
            case ApplicantStage.PanVerification:
              navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              break;
            case ApplicantStage.EmploymentDetails:
              navigate("/work-details", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AddressDetails:
              navigate("/address", { state: { data: location?.state?.data } });
              break;
            case ApplicantStage.BankStatement:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingApproval:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.CoapplicantRequired:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.AwaitingCoapplicantConsent:
              navigate("/parking-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Approved:
              // navigate("/select-emi", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.SanctionTermsAccepted:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Digilocker:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Selfie:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Agreement:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.BankAccountVerified:
              // navigate("/sanctions", {
              //   state: { data: location?.state?.data },
              // });
              break;
            case ApplicantStage.Mandate:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.PFCollections:
              navigate("/pf-collection", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.ProcessComplete:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Disbursed:
              navigate("/disbursed-page", {
                state: { data: location?.state?.data },
              });
              break;
            case ApplicantStage.Rejected:
              navigate("/rejected-page", {
                state: { data: location?.state?.data },
              });
              break;
            default:
              try {
                navigate(`/${atob(JSON.stringify(location?.state?.data))}`);
              } catch (err) {
                navigate(`/enter-pan`);
              }
              break;
          }
        }
      })
      .catch((err) => console.log(err));
  }, [location?.state?.data?.applicationId, location?.state?.data, navigate]);

  const fetchJsFromCDN = (src: any, externals: string[]) => {
    externals = !externals ? (externals = []) : externals;
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.setAttribute("src", src);
      script.addEventListener("load", () => {
        resolve(
          externals.map((key: any) => {
            const ext = window[key];
            typeof ext === "undefined" &&
              console.warn(`No external named '${key}' in window`);
            return ext;
          })
        );
      });
      script.addEventListener("error", reject);
      document.body.appendChild(script);
    });
  };

  const disabledCheckDigiLocker = (mandateStatus: MandateType) => {
    if (mandateStatus?.kycDone) {
      return true;
    } else if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return false;
    }
    return true;
  };

  const disabledCheckDigiLockerAfterSelfie = (mandateStatus: MandateType) => {
    if (mandateStatus?.digilockerStatus === DigilockerStatus.Successful) {
      return true;
    }
    if (mandateStatus?.selfieStatus === SelfieStatus.Failed) {
      return false;
    }
    if (mandateStatus?.kycDone) {
      return true;
    } else if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return false;
    }
    return true;
  };

  const disabledCheckSelfie = (mandateStatus: MandateType) => {
    if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return true;
    }
    if (mandateStatus?.selfieStatus !== SelfieStatus.Successful) {
      return false;
    }
    return true;
  };

  const disabledCheckAgreement = (mandateStatus: MandateType) => {
    if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return true;
    }
    if (mandateStatus?.selfieStatus === SelfieStatus.Failed) {
      if (mandateStatus?.agreementStatus === AgreementStatus.Signed) {
        return true;
      }
      return false;
    }
    if (mandateStatus?.selfieStatus !== SelfieStatus.Successful) {
      return true;
    }
    if (mandateStatus?.agreementStatus !== AgreementStatus.Signed) {
      return false;
    }
    return true;
  };

  const disabledCheckMandate = (mandateStatus: MandateType) => {
    if (
      !mandateStatus?.kycDone &&
      mandateStatus?.digilockerStatus !== DigilockerStatus.Successful
    ) {
      return true;
    }
    if (mandateStatus?.selfieStatus === SelfieStatus.Failed) {
      if (mandateStatus?.agreementStatus === MandateStatus?.Successful) {
        return true;
      }
      return false;
    }
    if (mandateStatus?.selfieStatus !== SelfieStatus.Successful) {
      return true;
    }
    if (mandateStatus?.agreementStatus === AgreementStatus.PendingCoapplicant) {
      return false;
    }
    if (mandateStatus?.agreementStatus !== AgreementStatus.Signed) {
      return true;
    }
    if (mandateStatus?.enachStatus !== MandateStatus?.Successful) {
      return false;
    }
    return true;
  };

  // useEffect(() => {
  //   if (
  //     mandateStatus?.selfieStatus === SelfieStatus.Successful &&
  //     mandateStatus?.enachStatus === MandateStatus?.Successful &&
  //     mandateStatus?.agreementStatus === AgreementStatus.Signed
  //   ) {
  //     navigate("/thank-you");
  //   }
  // }, [mandateStatus, navigate]);

  function digilockerHandler() {
    fetchJsFromCDN(process.env.REACT_APP_DIGIO_SDK, ["Digio"]).then(
      (digio: any) => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${authToken && authToken.value}`
        );

        var requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(`${process.env.REACT_APP_DASHBOARD_URL}/digilocker/create`, requestOptions)
          .then((response) => response.json())
          .then((result) => {
            // console.log(result);

            const { kid, tokenId, id } = result.data;

            Digio = digio[0];
            let d = new Digio({
              environment: process.env.REACT_APP_DIGIO_ENV,
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              is_iframe: true,
              callback: (_digio: any) => {
                // console.log("ALL: ", _digio);

                // delayedUpdateDigioCall(kid, id);

                if (_digio.error_code === "CANCELLED") {
                  // console.log("Flow cancelled by user");
                  // setFlag(!flag);
                  setFlag((prev) => !prev);
                  navigate("/sanctions", {
                    state: { data: { ...location?.state?.data } },
                  });
                  // callAuthApiDelayed();
                  return;
                }
                if (_digio.error_code !== undefined) {
                  // setFlag(!flag);
                  setFlag((prev) => !prev);
                  navigate("/sanctions", {
                    state: { data: { ...location?.state?.data } },
                  });
                  // callAuthApiDelayed();
                  throw new Error(_digio.message);
                }

                // setFlag(!flag);
                setFlag((prev) => !prev);
                navigate("/sanctions", {
                  state: { data: { ...location?.state?.data } },
                });
                // callAuthApiDelayed();

                // console.log(_digio);
              },
            });

            // console.log(d);

            d.init();
            d.submit(kid, authToken.mob, tokenId);
          })
          .catch((error) => console.log("error", error));
      }
    );
  }

  

  const loanAgreementHandler = () => {
    setLoaderAgreement(true); // Activate loader and disable button
    fetchJsFromCDN(process.env.REACT_APP_DIGIO_SDK, ["Digio"])
      .then((digio: any) => {
        var myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          `Bearer ${authToken && authToken.value}`
        );

        var requestOptions: RequestInit = {
          method: "POST",
          headers: myHeaders,
          redirect: "follow",
        };

        fetch(
          `${process.env.REACT_APP_DASHBOARD_URL}/agreement/${
            mandateStatus?.isCoapplicant ? "coapplicant-generate" : "generate"
          }`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            const { email, id, tokenId } = result.data;

            const Digio = digio[0];
            let d = new Digio({
              environment: process.env.REACT_APP_DIGIO_ENV,
              logo: "yourlogourl",
              theme: {
                primaryColor: "#234FDA",
                secondaryColor: "#234FDA",
              },
              digioDocumentId: id,
              digioUserIdentifier: email,
              is_iframe: true,
              callback: (_digio: any) => {
                // API call completed, disable loader and enable button
                setLoaderAgreement(false);
                if (_digio.error_code === "CANCELLED") {
                  // setFlag(!flag);
                  setFlag((prev) => !prev);
                  navigate("/sanctions", {
                    state: { data: { ...location?.state?.data } },
                  });
                  return;
                }
                if (_digio.error_code !== undefined) {
                  // setFlag(!flag);
                  setFlag((prev) => !prev);
                  navigate("/sanctions", {
                    state: { data: { ...location?.state?.data } },
                  });
                  throw new Error(_digio.message);
                }
                // setFlag(!flag);
                setFlag((prev) => !prev);
                navigate("/sanctions", {
                  state: { data: { ...location?.state?.data } },
                });
              },
            });

            d.init();
            d.submit(id, email, tokenId);
          })
          .catch((error) => {
            // API call failed, disable loader and enable button
            setLoaderAgreement(false);
            console.log("error", error);
          });
      })
      .catch((error) => {
        // Fetching digio.js failed, disable loader and enable button
        setLoaderAgreement(false);
        console.log("error", error);
      });
  };


 const data = sessionStorage.getItem("data")


  function selfieHandler() {
    fetch(`${process.env.REACT_APP_DASHBOARD_URL}/selfie/create`, {
      method: "POST",
      headers: {
        // Authorization: `Bearer ${authToken.value}`,
        Authorization: `Bearer ${authToken && authToken.value}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        // console.log(res);
        if (res.message === "success") {
          // console.log(res.data.url);
          console.log(process.env.REACT_APP_DIGIO_BASEURL, "----> redirect to")
          const redirect_url = window.open(
            `${process.env.REACT_APP_DIGIO_BASEURL}/#/gateway/login/${res.data.entityId}/vI3atY/${location?.state?.data?.mobile}?token_id=${res.data.tokenId}&redirect_url=${process.env.REACT_APP_FRONTEND_URL}/${data}`,
            "_self",
            "noopener,noreferrer"
          );

          //   navigation.navigate("NachRazorpay", {
          //     razorpayUrl: res.data.url,
          //   });
        } else {
          if (
            res.message ===
            "Oops. There is an issue at our end. Please contact our support team"
          ) {
            alert(
              "Please check the internet connection. Contact our support team if the error is not resolved."
            );
            // setError(
            //   "Please check the internet connection. Contact our support team if the error is not resolved."
            // );
          }
        }
      });
  }

  async function getRazorpayUrl() {
    setLoading(true);
    try {
      const response = await createNach({
        userId: location?.state?.data?.userId,
        applicationId: location?.state?.data?.applicationId,
      });
      console.log("response sub",response)
      window.open(response?.data?.authLink, "_self", "noopener,noreferrer");
    } catch (error) {
      console.error("Error fetching Razorpay URL", error);
    } finally {
      setLoading(false);
    }
  }


  // async function getCashfreepayUrl() {
  //   setLoading(true);
  //   try {
  //     const response = await createNach({
  //       userId: location?.state?.data?.userId,
  //       applicationId: location?.state?.data?.applicationId,
  //     });
  //     const responseFromSdk = SubscriptionsCheckoutSdk(response?.data)
  //   } catch (error) {
  //     console.error("Error fetching Razorpay URL", error);
  //   } finally {
  //     setLoading(false);
  //   }
  // }






  return (
    <div className={styles.body}>
      <div className={styles.backdrop}>
        <Navbar />
        <div className={styles.container}>
          <div className={styles.buttonList}>
            <br />
            <br />
            <br />
            <br />
            {loading ? (
               <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
               <CircularProgress />
             </Box>
            ):(
              <>
               {!mandateStatus?.kycDone && (
              <>
                <div className={styles.sanctionsListGrid}>
                  <img
                    src={
                      mandateStatus?.digilockerStatus === 3
                        ? GreenTick
                        : GreyDot
                    }
                    alt=""
                    className={styles.sanctionsGreenTick}
                  />
                  <p
                    className={styles.sanctionsProgressBarText}
                    style={{
                      color: disabledCheckDigiLocker(mandateStatus)
                        ? "#9a9a9a"
                        : "#000000",
                    }}
                  >
                    Digilocker KYC
                  </p>
                  {disabledCheckDigiLocker(mandateStatus) ? (
                    <div></div>
                  ) : (
                    <img
                      src={NextArrow}
                      alt=""
                      className={styles.sanctionNextArrow}
                      onClick={() => digilockerHandler()}
                    />
                  )}
                </div>
                <div className={styles.sanctionsListGrid}>
                  <div
                    className={
                      mandateStatus?.digilockerStatus === 3
                        ? styles.sanctionsProgressBar
                        : styles.sanctionsProgressBarGrey
                    }
                  ></div>
                  <div></div>
                  <div></div>
                </div>
              </>
            )}
            {mandateStatus?.selfieStatus !== SelfieStatus.Failed && (
              <>
                <div className={styles.sanctionsListGrid}>
                  <img
                    src={
                      mandateStatus?.selfieStatus === 3 ? GreenTick : GreyDot
                    }
                    alt=""
                    className={styles.sanctionsGreenTick}
                  />
                  <p
                    className={styles.sanctionsProgressBarText}
                    style={{
                      color: disabledCheckSelfie(mandateStatus)
                        ? "#9a9a9a"
                        : "#000000",
                    }}
                  >
                    Selfie
                  </p>
                  {disabledCheckSelfie(mandateStatus) ? (
                    <div></div>
                  ) : (
                    <img
                      src={NextArrow}
                      alt=""
                      className={styles.sanctionNextArrow}
                      onClick={() => {
                        selfieHandler();
                        // navigate("/sanctions/selfie", {
                        //   state: { data: { ...location?.state?.data } },
                        // });
                      }}
                    />
                  )}
                </div>
                <div className={styles.sanctionsListGrid}>
                  <div
                    className={
                      mandateStatus?.selfieStatus === 3
                        ? styles.sanctionsProgressBar
                        : styles.sanctionsProgressBarGrey
                    }
                  ></div>
                  <div></div>
                  <div></div>
                </div>
              </>
            )}
            {mandateStatus?.selfieStatus === SelfieStatus.Failed && (
              <>
                <div className={styles.sanctionsListGrid}>
                  <img
                    src={
                      mandateStatus?.digilockerStatus === 3
                        ? GreenTick
                        : GreyDot
                    }
                    alt=""
                    className={styles.sanctionsGreenTick}
                  />
                  <p
                    className={styles.sanctionsProgressBarText}
                    style={{
                      color: disabledCheckDigiLockerAfterSelfie(mandateStatus)
                        ? "#9a9a9a"
                        : "#000000",
                    }}
                  >
                    Digilocker KYC
                  </p>
                  {disabledCheckDigiLockerAfterSelfie(mandateStatus) ? (
                    <div></div>
                  ) : (
                    <img
                      src={NextArrow}
                      alt=""
                      className={styles.sanctionNextArrow}
                      onClick={() => digilockerHandler()}
                    />
                  )}
                </div>
                <div className={styles.sanctionsListGrid}>
                  <div
                    className={
                      mandateStatus?.digilockerStatus === 3
                        ? styles.sanctionsProgressBar
                        : styles.sanctionsProgressBarGrey
                    }
                  ></div>
                  <div></div>
                  <div></div>
                </div>
              </>
            )}
            <>
              <div className={styles.sanctionsListGrid}>
                <img
                  src={
                    mandateStatus?.agreementStatus === 3 ? GreenTick : GreyDot
                  }
                  alt=""
                  className={styles.sanctionsGreenTick}
                />
                <p
                  className={styles.sanctionsProgressBarText}
                  style={{
                    color:
                      disabledCheckAgreement(mandateStatus) || loaderAgreement
                        ? "#9a9a9a"
                        : "#000000",
                  }}
                >
                  Agreement
                </p>
                {disabledCheckAgreement(mandateStatus) || loaderAgreement ? (
                  <div></div>
                ) : (
                  <img
                    src={NextArrow}
                    alt=""
                    className={styles.sanctionNextArrow}
                    onClick={() => loanAgreementHandler()}
                  />
                )}
              </div>
              <div className={styles.sanctionsListGrid}>
                <div
                  className={
                    mandateStatus?.agreementStatus === 3
                      ? styles.sanctionsProgressBar
                      : styles.sanctionsProgressBarGrey
                  }
                ></div>
                <div></div>
                <div></div>
              </div>
            </>
            <div className={styles.sanctionsListGrid}>
              <img
                src={mandateStatus?.enachStatus === 4 ? GreenTick : GreyDot}
                alt=""
                className={styles.sanctionsGreenTick}
              />
              <p
                className={styles.sanctionsProgressBarText}
                style={{
                  color: disabledCheckMandate(mandateStatus)
                    ? "#9a9a9a"
                    : "#000000",
                }}
              >
                e-Mandate
              </p>
              {disabledCheckMandate(mandateStatus) ? (
                <div></div>
              ) : (
                <img
                  src={NextArrow}
                  alt=""
                  className={styles.sanctionNextArrow}
                  onClick={() =>{ 
                    // getCashfreepayUrl()
                    getRazorpayUrl()
                  }
                  }
                />
              )}
            </div>
              </>
            )}
           
            {/* 
            <Button
              disabled={disabledCheckMandate(mandateStatus)}
              text={"e-Mandate"}
              onPress={() => {
                // navigate("/digio-mandate");
                navigate("/razorpay-mandate");
              }}
              imageRight={
                mandateStatus?.enachStatus === 4 ? CheckCircleIcon : null
              }
              fullWidth
            /> */}

            {/* {loaderAgreement && <ClipLoader size={35} color={"#123abc"} loading={loaderAgreement}  />} */}
            {/* FIXME: From up there */}
            {/* <Button
              text="Digilocker KYC"
              onPress={() => {}}
              imageRight={CheckCircleIcon}
              fullWidth
            />
            <Button
              text="Selfie"
              onPress={() => {}}
              imageRight={CheckCircleIcon}
              fullWidth
            />
            <Button
              text="Agreement"
              onPress={() => {}}
              imageRight={CheckCircleIcon}
              fullWidth
            />
            <Button
              text="e-Mandate"
              onPress={() => {}}
              imageRight={CheckCircleIcon}
              fullWidth
            /> */}
            {process.env.REACT_APP_CURRENT === "dev" ?(
              <button
              onClick={() => {
                console.log("Clicked");
                // console.log(redirectUrl);

                fetch(`${process.env.REACT_APP_DASHBOARD_URL}/nach/skip`, {
                  method: "POST",
                  headers: {
                    "Content-Type": "application/json",
                  },
                  body: JSON.stringify({
                    userId: location?.state?.data?.userId,
                    applicationId: location?.state?.data?.applicationId,
                  }),
                }).then((res) => {
                  navigate("/disbursed-page", {
                    state: { data: { ...location?.state?.data } },
                  });
                });

                // window.open(redirectUrl, "_self");
              }}
            >
              Skip (for dev purposes)
            </button>
            ):null}
            
          </div>

          <p className={styles.footerText}>
            Powered By
            <img
              className={styles.footerImage}
              src="https://www.digio.in/images/digio_blue.png"
              alt=""
            />
          </p>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export default Sanctions;

// {!mandateStatus?.kycDone && (
//   <Button
//     disabled={disabledCheckDigiLocker(mandateStatus)}
//     text="Digilocker KYC"
//     onPress={() => {
//       digilockerHandler();
//     }}
//     imageRight={
//       mandateStatus?.digilockerStatus === 3 ? CheckCircleIcon : null
//     }
//     fullWidth
//   />
// )}
// {mandateStatus?.selfieStatus !== SelfieStatus.Failed && (
//   <Button
//     disabled={disabledCheckSelfie(mandateStatus)}
//     text="Selfie"
//     onPress={}
//     imageRight={
//       mandateStatus?.selfieStatus === 3 ? CheckCircleIcon : null
//     }
//     fullWidth
//   />
// )}
// {mandateStatus?.selfieStatus === SelfieStatus.Failed && (
//   <Button
//     disabled={disabledCheckDigiLockerAfterSelfie(mandateStatus)}
//     text="Digilocker KYC"
//     onPress={() => {
//       digilockerHandler();
//     }}
//     imageRight={
//       mandateStatus?.digilockerStatus === 3 ? CheckCircleIcon : null
//     }
//     fullWidth
//   />
// // )}
// <Button
// disabled={
//   disabledCheckAgreement(mandateStatus) || loaderAgreement
// }
// text="Agreement"
// onPress={loanAgreementHandler}
// imageRight={
//   mandateStatus?.agreementStatus === 3 ? CheckCircleIcon : null
// }
// fullWidth
// />
